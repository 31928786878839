





































































































import {Modal} from '@simpli/vue-modal'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {FilterWalletSchema} from '@/schema/resource/Wallet/FilterWalletSchema'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {DappConnection} from '@/model/resource/DappConnection'
import {PlatformType} from '@/model/resource/PlatformType'
import {$} from '@/facade'

@Component({
  components: {
    Modal,
    InputCheckbox,
    InputText,
  },
})
export default class FilterWalletMobile extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: WalletCollection

  schema = new FilterWalletSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionDapp.queryAsPage(),
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
      this.collection.resource.collectionDappConnection.queryAsPage(),
      this.collection.resource.collectionPlatformType.queryAsPage(),
    ]

    await $.await.run('softQuery', () => Promise.all(promises))
  }

  hasValue(list = [], value: number) {
    return list.some((item: never) => item === value)
  }

  async doFilter() {
    this.$emit('submit')
  }

  addBlockchain(param: BlockchainVersion) {
    const walletBlockchainVersions =
      this.collection.walletBlockchainVersion || []

    if (walletBlockchainVersions?.includes(param)) {
      this.collection.walletBlockchainVersion = walletBlockchainVersions?.filter(
        walletBlockchainVersion =>
          walletBlockchainVersion.idBlockchainVersionPk !==
          walletBlockchainVersion.idBlockchainVersionPk
      )

      return
    }

    this.collection.walletBlockchainVersion = [
      ...walletBlockchainVersions,
      param,
    ]
  }

  addWallet(param: DappConnection) {
    const walletDappConnections = this.collection.walletDappConnection || []

    if (walletDappConnections?.includes(param)) {
      this.collection.walletDappConnection = walletDappConnections?.filter(
        walletBlockchainVersion =>
          walletBlockchainVersion.idDappConnectionPk !==
          walletBlockchainVersion.idDappConnectionPk
      )

      return
    }

    this.collection.walletDappConnection = [...walletDappConnections, param]
  }

  addPlatform(param: PlatformType) {
    const walletPlatformTypes = this.collection.walletPlatformType || []

    if (walletPlatformTypes?.includes(param)) {
      this.collection.walletPlatformType = walletPlatformTypes?.filter(
        walletPlatformType =>
          walletPlatformType.idPlatformTypePk !==
          walletPlatformType.idPlatformTypePk
      )
      return
    }

    this.collection.walletPlatformType = [...walletPlatformTypes, param]
  }

  doClear() {
    this.collection.walletBlockchainVersion = []
    this.collection.walletDappConnection = []
    this.collection.walletPlatformType = []
    this.collection.search = ''
    this.doFilter()
  }
}
